import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { EMBEDDED_FIND_HOME_PATH } from '../legacy-pages/embedded-find-home'

import { useEffectOnMount } from './use-effect-on-mount'
export const useEmbeddedFindHome = () => {
  const [isFindHomeEmbedded, setIsFindHomeEmbedded] = useState(false)
  const location = useLocation()
  const currentPathname = location.pathname
  const currentPathnameWithDash = location.pathname + '/'
  const isCurrentPathEmbedded =
    currentPathname === EMBEDDED_FIND_HOME_PATH || currentPathnameWithDash === EMBEDDED_FIND_HOME_PATH

  useEffectOnMount(() => {
    setIsFindHomeEmbedded(isCurrentPathEmbedded)
  })

  return isFindHomeEmbedded
}
