import { useEffect, useMemo, useRef, useState } from 'react'
import type { Dispatch } from 'react'
import { useMutation } from '@apollo/client'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { isBefore } from 'date-fns'
import type {
  ListingQuery,
  ListingAvailabilitySettingsQuery,
  ListingAvailabilityQuery,
  Market,
  InputMaybe,
} from '@qasa/graphql'
import {
  HomeAvailabilityStatusEnum,
  HomeRentalTypeEnum,
  CountryCodeEnum,
  MarketNameEnum,
} from '@qasa/graphql'

import { usePreviousValue } from '../../hooks/use-previous-value'
import { ENV } from '../../helpers/env'
import { AVAILABLE_LOCALES } from '../../translations/language-loader/language-utils'
import { useEffectOnMount } from '../../hooks/use-effect-on-mount'
import { useCurrentLocale } from '../../translations/use-current-locale'
import { availableMarketCountries } from '../../brands/markets'

import { EDIT_LISTING } from './edit-listing.gql'
import type {
  ListingStore,
  ListingStoreValues,
  SetErrorsAction,
  UpdateFieldValueAction,
} from './edit-listing.types'
import { FieldEnum, shouldExcludeField } from './edit-listing.types'
import { useGetErrors } from './listing-input-validations'
import { CHECK_IN_DAYS } from './sections/calendar-settings-section'
import { RECOMMENDED_RENT_ERROR_RATIO } from './listing.constants'

const longDebouncer = debounce(({ callback }) => {
  callback()
}, 3000)

const shortDebouncer = debounce(({ callback }) => {
  callback()
}, 300)

const loadedGooglePlacesScripts: string[] = []

const useLoadGooglePlaces = () => {
  const locale = useCurrentLocale()
  const currentLanguageCountryCode =
    AVAILABLE_LOCALES.find(({ languageCode }) => languageCode === locale)?.ietfCode ?? null
  useEffect(() => {
    const googleMapScript = document.createElement('script')
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${ENV.GOOGLE_MAPS_KEY}&libraries=places${currentLanguageCountryCode ? `&language=${currentLanguageCountryCode}` : ''}`
    if (!loadedGooglePlacesScripts.includes(googleMapScript.src)) {
      loadedGooglePlacesScripts.push(googleMapScript.src)
      googleMapScript.async = true
      window.document.body.appendChild(googleMapScript)
    }
  }, [currentLanguageCountryCode])

  return { googlePlaces: (window as LegitimateAny).google?.maps?.places }
}

export type UseGoogleAutocompleteSuggestionsParams = {
  shouldRestrictToSupportedCountries?: boolean
  allowedCountries?: (CountryCodeEnum | 'fi' | 'fr' | 'se')[]
}

export const useGoogleAutocompleteSuggestions = ({
  shouldRestrictToSupportedCountries = true,
  allowedCountries = availableMarketCountries,
}: UseGoogleAutocompleteSuggestionsParams) => {
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false)
  const [locationSuggestions, setLocationSuggestions] = useState<
    google.maps.places.QueryAutocompletePrediction[]
  >([])

  const { googlePlaces } = useLoadGooglePlaces()

  /* Doing this semi-hack to not rerender when array dependency has in fact not changed */
  const allowedCountriesStringified = JSON.stringify(allowedCountries)

  const fetchLocationSuggestions = useMemo(
    () =>
      debounce((inputValue: string) => {
        if (googlePlaces) {
          try {
            setIsFetchingSuggestions(true)

            const service = new googlePlaces.AutocompleteService()
            service.getPlacePredictions(
              {
                input: inputValue,
                ...(shouldRestrictToSupportedCountries && {
                  componentRestrictions: { country: JSON.parse(allowedCountriesStringified) },
                }),
              },
              (
                predictions: google.maps.places.QueryAutocompletePrediction[] | null,
                status: google.maps.places.PlacesServiceStatus,
              ) => {
                setIsFetchingSuggestions(false)
                if (status !== googlePlaces.PlacesServiceStatus.OK || !predictions) {
                  setLocationSuggestions([])
                  return
                }
                setLocationSuggestions(predictions)
              },
            )
          } catch (err) {
            setIsFetchingSuggestions(false)
          }
        }
      }, 300),
    [googlePlaces, shouldRestrictToSupportedCountries, allowedCountriesStringified],
  )
  return {
    isLoading: isFetchingSuggestions,
    locationSuggestions,
    fetchLocationSuggestions,
  }
}

export const useFetchLocationFromGooglePlaceId = () => {
  const { googlePlaces } = useLoadGooglePlaces()

  const fetchLocationFromGooglePlaceId = ({
    placeId,
  }: {
    placeId: google.maps.places.QueryAutocompletePrediction['place_id']
  }) => {
    return new Promise<google.maps.places.PlaceResult | null>((resolve, reject) => {
      if (!googlePlaces) {
        reject('no places service available')
      }
      const service = new googlePlaces.PlacesService(document.createElement('div'))

      service.getDetails(
        {
          placeId,
        },
        (details: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
          if (status === googlePlaces.PlacesServiceStatus.OK) {
            resolve(details)
          } else {
            reject(status)
          }
        },
      )
    })
  }
  return { fetchLocationFromGooglePlaceId }
}

export function mapHomeToListingStore({ home }: { home: NonNullable<ListingQuery['home']> }) {
  return {
    ...omit(home, 'location'),
    ...omit(home.location, 'latitude', 'longitude'),
    position: {
      latitude: home.location.latitude,
      longitude: home.location.longitude,
    },
    waterFeePerTenant: home.tenantFees.waterFeePerTenant.cost.fixed || 0,
    countryCode: home.location.countryCode,
    rent: home.rent || 0,
    cancellationPolicyName: home.currentCancellationPolicy?.policyName,
    duration: omit(home.duration, '__typename') || undefined,
    traits: home.traits.map((trait) => trait.type),
    minRoomCount: home.property?.minRoomCount || null,
    maxRoomCount: home.property?.maxRoomCount || null,
    minSquareMeters: home.property?.minSquareMeters || null,
    maxSquareMeters: home.property?.maxSquareMeters || null,
    numberOfHomes: home.property?.numberOfHomes ?? null,
    isExternal: home.external,
  }
}

export function mapAvailabilityToListingStore({
  availabilitySettingsData,
  availabilityData,
}: {
  availabilitySettingsData: ListingAvailabilitySettingsQuery
  availabilityData: ListingAvailabilityQuery
}) {
  const checkInDays = CHECK_IN_DAYS.filter(
    (checkinDay) =>
      !availabilitySettingsData?.homeAvailabilitySettingsQuery?.noCheckinDays?.includes(checkinDay.value),
  ).map((checkinDay) => checkinDay.value)
  return {
    minNights: availabilitySettingsData?.homeAvailabilitySettingsQuery?.minNightsStay,
    isRentOnlyWeekly: availabilitySettingsData?.homeAvailabilitySettingsQuery?.rentOnlyWeekly,
    checkInDays,
    availableDates: (availabilityData?.homeAvailabilities
      .filter(
        (availability) =>
          availability.status === HomeAvailabilityStatusEnum.available &&
          !isBefore(new Date(availability?.date as string), new Date()),
      )
      .map((availability) => availability?.date) || []) as string[],
  }
}

export function getCreateHomeInput({ storeValues }: { storeValues: ListingStoreValues }) {
  const {
    isProfessional,
    type,
    tenureType,
    shared, // eslint-disable-line @typescript-eslint/naming-convention
    traits,
    firsthand, // eslint-disable-line @typescript-eslint/naming-convention
    seniorHome, // eslint-disable-line @typescript-eslint/naming-convention
    studentHome, // eslint-disable-line @typescript-eslint/naming-convention
    corporateHome, // eslint-disable-line @typescript-eslint/naming-convention
    position: { latitude, longitude },
    locality,
    postalCode,
    route,
    streetNumber,
    apartmentNumber,
    countryCode,
    country,
    formattedAddress,
    floor,
    buildingFloors,
    roomCount,
    squareMeters,
    minSquareMeters,
    maxSquareMeters,
    maxRoomCount,
    minRoomCount,
    numberOfHomes,
    qasaGuarantee, // eslint-disable-line @typescript-eslint/naming-convention
    insurance,
    rent,
    duration,
    ownHome, // eslint-disable-line @typescript-eslint/naming-convention
    rentalType,
    hasKitchen,
    bedCount,
    bedroomCount,
    toiletCount,
    tenantCount,
    origin,
  } = storeValues

  return {
    duration,
    ownHome: ownHome !== null ? ownHome : undefined,
    rent: rent || 0,
    shared: shared || false,
    traits,
    type: type!,
    tenureType,
    tenantCount: tenantCount || Math.floor(Number(roomCount)) + 1, //FIXME: ADD RULE TO BE
    location: {
      latitude,
      longitude,
      locality,
      postalCode,
      route,
      streetNumber,
      countryCode,
      country,
      formattedAddress,
    },
    floor,
    buildingFloors,
    apartmentNumber,
    roomCount,
    squareMeters,
    ...(isProfessional && {
      numberOfHomes,
      firsthand,
      seniorHome,
      studentHome,
      corporateHome,
      ...(numberOfHomes! > 1 && {
        maxRoomCount,
        maxSquareMeters,
        minRoomCount,
        minSquareMeters,
        roomCount: Math.round((maxRoomCount! + minRoomCount!) / 2),
        squareMeters: Math.round((maxSquareMeters! + minSquareMeters!) / 2),
      }),
    }),
    qasaGuarantee,
    insurance,
    rentalType,
    hasKitchen,
    bedroomCount,
    bedCount,
    toiletCount,
    origin,
  }
}

const mapActionToMutationInput = ({ action }: { action: UpdateFieldValueAction }) => {
  // This is needed because we want to have location flattened to be able to validate each field (using the current
  // validation system).
  // Also, the Position field is for convenience, to validate latitude and longitude at the same time on change. This does however
  // lead to the below mapping being necessary before sending the fields as mutation input.
  if (
    [
      FieldEnum.Position,
      FieldEnum.Locality,
      FieldEnum.PostalCode,
      FieldEnum.Route,
      FieldEnum.StreetNumber,
      FieldEnum.CountryCode,
      FieldEnum.Country,
      FieldEnum.FormattedAddress,
    ].includes(action.type)
  ) {
    return {
      location: action.type === FieldEnum.Position ? action.payload : { [action.type]: action.payload },
    }
  }

  if (action.type === FieldEnum.IsExternal) {
    return { external: action.payload }
  }

  return { [action.type]: action.payload }
}

type UseValidateAndMutationOnChange = {
  store: ListingStore
  dispatch: Dispatch<SetErrorsAction>
  homeId?: string
}

export const useValidateAndMutationOnChange = ({
  store,
  dispatch,
  homeId,
}: UseValidateAndMutationOnChange) => {
  const previousStore = usePreviousValue(store)
  const errors = useGetErrors({ values: store.values })
  const previousErrors = usePreviousValue(errors)
  const lastUpdatedField = useRef<FieldEnum | null>(null)
  const [isUpToDate, setIsUpToDate] = useState(true)
  const [editListing] = useMutation(EDIT_LISTING, {
    onCompleted: () => setIsUpToDate(true),
  })
  const isEdit = Boolean(homeId)
  const hasNewErrors = !isEqual(previousErrors, errors)
  const updateListing = ({ field }: { field: FieldEnum }) => {
    // Function is only called if isEdit is true
    setIsUpToDate(false)
    const input = mapActionToMutationInput({
      action: {
        type: field,
        payload: store.values[field as keyof ListingStoreValues],
      } as UpdateFieldValueAction,
    })
    if (field === lastUpdatedField.current) {
      /* Needed to ensure correct rent has been saved before overview step */
      /* Not a great solution but I think/hope all of this is prone to change soon anyway*/
      const debouncer = field === FieldEnum.Rent ? shortDebouncer : longDebouncer
      debouncer({
        callback: () => {
          editListing({ variables: { input, homeId: homeId! } })
        },
      })
    } else {
      const modifiedInput = lastUpdatedField.current
        ? {
            ...mapActionToMutationInput({
              action: {
                type: lastUpdatedField.current,
                payload: store.values[lastUpdatedField.current as keyof ListingStoreValues],
              } as UpdateFieldValueAction,
            }),
            ...input,
          }
        : input
      editListing({ variables: { input: modifiedInput, homeId: homeId! } })
    }
    lastUpdatedField.current = field
  }
  const validate = async ({ field }: { field: keyof typeof errors }) => {
    const fieldErrors = await errors[field]
    if (fieldErrors) {
      dispatch({ type: 'SET_ERRORS', payload: { field, errors: fieldErrors } } as SetErrorsAction)
    }
  }

  // validate on mount
  useEffectOnMount(() => {
    Object.keys(errors).forEach(async (field) => {
      validate({ field: field as FieldEnum })
    })
  })

  // validate field when its value has changed, run mutation if in edit mode and field is valid
  useEffect(() => {
    if (hasNewErrors) {
      Object.keys(errors).forEach(async (field) => {
        if (!isEqual(previousErrors[field as FieldEnum], errors[field as FieldEnum])) {
          validate({ field: field as FieldEnum })
        }
      })
    }
    Object.keys(store.values).forEach(async (field) => {
      const previousFieldValue = previousStore.values[field as keyof ListingStoreValues]
      const currentFieldValue = store.values[field as keyof ListingStoreValues]
      const isFieldWithNewValue = !isEqual(previousFieldValue, currentFieldValue)
      if (isFieldWithNewValue) {
        const fieldErrors = await errors[field as FieldEnum]
        const hasErrors = Boolean(fieldErrors?.length)
        if (hasErrors && field === lastUpdatedField.current) {
          lastUpdatedField.current = null
        }
        if (isEdit && !fieldErrors?.length && !shouldExcludeField(field as FieldEnum)) {
          updateListing({ field: field as FieldEnum })
        }
      }
    })
  }, [store.values]) // eslint-disable-line react-hooks/exhaustive-deps

  return { isUpToDate } as const
}

export const useDispatchPreloadedValueChanges = ({
  preloadedValues,
  dispatch,
}: {
  preloadedValues?: Partial<ListingStoreValues>
  dispatch: Dispatch<UpdateFieldValueAction | SetErrorsAction>
}) => {
  const { qasaGuaranteeCost, insuranceCost, recommendedRentNew, uploads } = preloadedValues || {}
  useEffect(() => {
    dispatch({ type: FieldEnum.GuaranteeCost, payload: qasaGuaranteeCost } as UpdateFieldValueAction)
  }, [dispatch, qasaGuaranteeCost])
  useEffect(() => {
    dispatch({ type: FieldEnum.InsuranceCost, payload: insuranceCost } as UpdateFieldValueAction)
  }, [dispatch, insuranceCost])
  useEffect(() => {
    dispatch({ type: FieldEnum.RecommendedRentNew, payload: recommendedRentNew } as UpdateFieldValueAction)
  }, [dispatch, recommendedRentNew])
  useEffect(() => {
    dispatch({ type: FieldEnum.Uploads, payload: uploads } as UpdateFieldValueAction)
  }, [dispatch, uploads])
}

export const getIsProfessionalWithSeveralListings = ({
  isProfessional,
  numberOfHomes,
}: Pick<ListingStoreValues, 'isProfessional' | 'numberOfHomes'>) => {
  return Boolean(isProfessional && numberOfHomes && numberOfHomes > 1)
}

export const getRelevantCreateListingFields = ({ values }: { values: ListingStoreValues }) => {
  const { isProfessional, numberOfHomes, rentalType } = values
  const shouldRenderRanges = getIsProfessionalWithSeveralListings({ isProfessional, numberOfHomes })
  const isVacationRental = rentalType === HomeRentalTypeEnum.vacation

  return [
    FieldEnum.IsProfessional,
    ...(isProfessional ? [FieldEnum.CompanyName, FieldEnum.OrganizationNumber] : []),
    ...(isProfessional && !isVacationRental ? [FieldEnum.NumberOfHomes] : []),
    FieldEnum.Shared,
    ...(shouldRenderRanges
      ? [FieldEnum.MinSquareMeters, FieldEnum.MaxSquareMeters, FieldEnum.MinRoomCount, FieldEnum.MaxRoomCount]
      : [FieldEnum.SquareMeters, FieldEnum.RoomCount]),
    FieldEnum.Student,
    FieldEnum.Firsthand,
    FieldEnum.Type,
    FieldEnum.Senior,
    FieldEnum.Corporate,
    ...(isVacationRental
      ? [
          FieldEnum.BedroomCount,
          FieldEnum.BedCount,
          FieldEnum.ToiletCount,
          FieldEnum.HasKitchen,
          FieldEnum.RentalType,
        ]
      : []),
    FieldEnum.Locality,
    FieldEnum.PostalCode,
    FieldEnum.StreetNumber,
    FieldEnum.Route,
    FieldEnum.Position,
    FieldEnum.FormattedAddress,
    FieldEnum.Country,
    FieldEnum.CountryCode,
  ]
}

export const getUrlWithoutTrailingSlash = ({ url }: { url: string }) =>
  url.endsWith('/') ? url.slice(0, -1) : url

type GetRentRelationToRecommendedRentParams = {
  rent: number
  recommendedRent: number | null
}
export const getRentRelationToRecommendedRent = ({
  rent,
  recommendedRent,
}: GetRentRelationToRecommendedRentParams) => {
  if (!recommendedRent || !rent) {
    return null
  }
  const differenceFactor = rent / recommendedRent
  if (differenceFactor > 1 + RECOMMENDED_RENT_ERROR_RATIO) {
    return 'high'
  }
  if (differenceFactor < 1 - RECOMMENDED_RENT_ERROR_RATIO) {
    return 'low'
  }
  return 'average'
}

/**
 * Converts a string representation of a number to its numeric equivalent.
 * Returns null if the input is empty or not a valid number
 */
export const numberFromString = (value: string) => {
  if (value === '') {
    return null
  }

  const parsed = Number(value)
  return Number.isNaN(parsed) ? null : parsed
}

type GetIsProbablyFinlandMarket = {
  marketName: Market['name'] | undefined
  countryCode: InputMaybe<string>
}
/**
 * NOTE: Due to create and edit listing sharing components, we need to check both marketName and countryCode.
 * This is because the market is only defined when we have a home created.
 */
export const getIsProbablyFinlandMarket = ({ marketName, countryCode }: GetIsProbablyFinlandMarket) => {
  if (marketName) {
    return marketName === MarketNameEnum.finland
  }
  return countryCode === CountryCodeEnum.FI
}
