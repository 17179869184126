import { graphql } from '@qasa/graphql'
import { Heading, LoadingDots, Spacer, Stack, useBreakpointValue } from '@qasa/qds-ui'
import { styled } from '@qasa/ui/web'
import type { FunctionComponent } from 'react'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'

import type { FindHomeNode } from '../../types/find-home'
import { useLocalStorage } from '../../hooks/use-local-storage.web'
import { Error } from '../../components/error'
import { useLocaleConfig } from '../../configs/use-locale-config'

import { useFindHomeContext } from './contexts/find-home-context'
import { useFindHomeFiltersContext } from './contexts/find-home-filter-context'
import { usePersistedMapState } from './map/use-persisted-map-state.web'
import { filtersToHomeSearchParams, useFormattedTitle } from './find-home.utils'
import { LongTermSearch } from './long-term-search.web'
import { HideInEmbeddedFindHomeWrapper } from './hide-in-embedded-find-home-wrapper'
import { MapToggle, Sorting } from './index.web'
import {
  LeftColumn,
  MainContent,
  SearchSectionWrapper,
  ListOfHomesSection,
  MapContent,
} from './page-layout.web'
import { ListOfHomes } from './result-list'
import { SearchHistoryProvider } from './search-history'
import { HomeListResultText } from './result-list/home-list-result-text'

const HOME_SEARCH_COORDS = graphql(`
  query HomeSearchCoordsQuery(
    $market: MarketNameTypeEnum
    $searchParams: HomeSearchParamsInput
    $filterOnArea: Boolean
  ) {
    homeSearchCoords(market: $market, searchParams: $searchParams, filterOnArea: $filterOnArea) {
      filterHomesRaw
    }
  }
`)

// No idea if this lazy import actually works with the way our imports from @qasa/app are set up now.
// Might be the same to lazy import @qasa/app, but that feels like it definitely wont work for some reason.
// eslint-disable-next-line @typescript-eslint/promise-function-async
const Map = lazy(() =>
  import('./map/map.web').then(({ Map }) => ({
    default: Map,
  })),
)

const ResultsParagraph = styled(HomeListResultText)(({ theme }) => ({
  paddingLeft: theme.spacing['2x'],
}))

type Props = {
  searchComponent?: FunctionComponent
  offeringInfoBoxComponent?: FunctionComponent
}
export function FindHome({
  searchComponent: SearchComponent = LongTermSearch,
  offeringInfoBoxComponent: OfferingInfoBox,
}: Props) {
  const { rentalType, homesError, areas, isLoadingAreas } = useFindHomeContext()
  const [hoveredHome, setHoveredHome] = useState<FindHomeNode | null>(null)
  const isXlOrAbove = useBreakpointValue({ base: false, xl: true })
  const { filterValues } = useFindHomeFiltersContext()
  const { persistedMapState, setPersistedMapState } = usePersistedMapState()
  const [isTwoColumnMapOpen, setIsTwoColumnMapOpen] = useLocalStorage<boolean | null>('defaultMapState', true)
  const [isFullScreenMapOpen, setIsFullScreenMapOpen] = useState(persistedMapState?.isMapOpen || false)
  const isMapOpen = isXlOrAbove ? Boolean(isTwoColumnMapOpen) : isFullScreenMapOpen
  const { market, currency } = useLocaleConfig()

  const title = useFormattedTitle({ filterValues, rentalType })
  useEffect(() => {
    /* TODO */
    /* Combine these to both use the same persistant state (ls/cookie), along with the search areas */
    setPersistedMapState({ isMapOpen })
  }, [isMapOpen, setPersistedMapState, setIsTwoColumnMapOpen])

  useEffect(() => {
    if (isMapOpen && !isXlOrAbove) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isMapOpen, isXlOrAbove])

  const {
    error: coordsError,
    data: coordsData,
    loading: isLoadingLocations,
  } = useQuery(HOME_SEARCH_COORDS, {
    variables: {
      filterOnArea: false,
      market,
      searchParams: {
        ...filtersToHomeSearchParams({
          searchParams: filterValues,
          areas,
          rentalType,
        }),
        currency,
      },
    },
    skip: !isMapOpen,
  })

  const handleHoveredHome = ({ home }: { home: FindHomeNode | null }) => setHoveredHome(home)

  if (coordsError && homesError) {
    return <Error error={homesError} />
  }

  return (
    <>
      <Stack direction="row">
        <LeftColumn>
          <MainContent>
            <HideInEmbeddedFindHomeWrapper>
              <Heading size={'md'} as="h1">
                {title}
              </Heading>
              <Spacer size={'6x'} />
            </HideInEmbeddedFindHomeWrapper>
            <SearchSectionWrapper>
              <SearchHistoryProvider rentalType={rentalType}>
                <SearchComponent />
              </SearchHistoryProvider>
              <Spacer size={'12x'} />
              <HideInEmbeddedFindHomeWrapper>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <ResultsParagraph />
                  <Sorting />
                </Stack>
              </HideInEmbeddedFindHomeWrapper>
            </SearchSectionWrapper>
            <Spacer size={'2x'} />
            <ListOfHomesSection>
              {homesError ? (
                <Error error={homesError} />
              ) : (
                <ListOfHomes
                  isMapOpen={isMapOpen}
                  onHoveredHome={handleHoveredHome}
                  offeringInfoBox={OfferingInfoBox}
                />
              )}
            </ListOfHomesSection>
          </MainContent>
        </LeftColumn>

        <MapContent isMapOpen={isMapOpen}>
          <Sentry.ErrorBoundary>
            {coordsError ? (
              <Error error={coordsError} />
            ) : (
              <Suspense fallback={<LoadingDots />}>
                <Map
                  isMapOpen={isMapOpen}
                  coordsData={coordsData}
                  hoveredHome={hoveredHome}
                  areas={areas}
                  isLoadingLocations={isLoadingLocations}
                  isLoadingPolygons={isLoadingAreas}
                />
              </Suspense>
            )}
          </Sentry.ErrorBoundary>
        </MapContent>
      </Stack>

      <HideInEmbeddedFindHomeWrapper>
        <Spacer size="6x" />
        <MapToggle
          isShowingMap={isMapOpen}
          onPress={() => {
            isXlOrAbove
              ? setIsTwoColumnMapOpen((isOpen) => !isOpen)
              : setIsFullScreenMapOpen((isOpen) => !isOpen)
          }}
        />
      </HideInEmbeddedFindHomeWrapper>
    </>
  )
}
