import type { ReactNode } from 'react'
import { useContext, createContext } from 'react'

export type Locale = 'se' | 'fi' | 'fr' | 'international'

type LocaleContextType = {
  locale: Locale
}
const LocaleContext = createContext<LocaleContextType | undefined>(undefined)

type Props = {
  children: ReactNode
  locale: Locale
}
function LocaleProvider({ children, locale }: Props) {
  return <LocaleContext.Provider value={{ locale }}>{children}</LocaleContext.Provider>
}

const useLocaleContext = () => {
  const context = useContext(LocaleContext)
  if (!context) {
    throw new Error('useLocaleContext must be used within a LocaleProvider')
  }
  return context
}

export { LocaleProvider, useLocaleContext }
