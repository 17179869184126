import { Identity } from '@schibsted/account-sdk-browser'
import { reportError } from '@qasa/app'

import { BRAND_TOGGLES } from '../brands/toggles'
import { ENV } from '../helpers/env'
import type { AfterLoginAction } from '../helpers/auth-callback.types'
import { encodeAfterLoginParameters } from '../helpers/auth-callback'
import type { SchibstedSSOMarket } from '../brands/index.types'

export const getRedirectUrl = () => `${window.location.origin}/auth_callback`

const identityClients: Record<SchibstedSSOMarket, Identity | null> = {
  schibsted_fi: null,
  schibsted_se: null,
}

const schibstedMarkets = BRAND_TOGGLES.availableLoginMethods.filter((method): method is SchibstedSSOMarket =>
  ['schibsted_fi', 'schibsted_se'].includes(method),
)

const MARKET_ENV_VALUES = {
  schibsted_se: {
    clientId: ENV.SCHIBSTED_SE_CLIENT_ID!,
    env: ENV.SCHIBSTED_CLIENT_ENV!,
    sessionDomain: ENV.SCHIBSTED_SE_SESSION_DOMAIN!,
  },
  schibsted_fi: {
    clientId: ENV.SCHIBSTED_FI_CLIENT_ID!,
    env: ENV.SCHIBSTED_CLIENT_ENV!,
    sessionDomain: ENV.SCHIBSTED_FI_SESSION_DOMAIN!,
  },
}

try {
  if (schibstedMarkets.length > 0 && typeof window !== 'undefined') {
    schibstedMarkets.forEach((market) => {
      if (!identityClients[market]) {
        identityClients[market] = new Identity({
          ...MARKET_ENV_VALUES[market],
          redirectUri: getRedirectUrl(),
        })
      }
    })
  }
} catch (error) {
  if (process.env.NODE_ENV === 'production') {
    reportError('Missing parameters when initializing schibsted identity client', {})
  }
}

type LoginParams = {
  state: string
  market: SchibstedSSOMarket
}
export function login({ state, market }: LoginParams) {
  const identityClient = identityClients[market]
  if (!identityClient) {
    return null
  }
  return identityClient.login({ state, scope: 'openid profile email phone' })
}

type LogoutParams = {
  redirectUri?: string
  market?: SchibstedSSOMarket
}
export function logout({ redirectUri = window.location.href, market }: LogoutParams) {
  if (!market) {
    return
  }
  const identityClient = identityClients[market]
  if (!identityClient) {
    return
  }
  identityClient.logout(redirectUri)
  return
}

export function logoutAll() {
  Object.values(identityClients).forEach((client) => {
    client?.logout()
  })
}

export async function getIsLoggedInWithSchibsted() {
  const isLoggedInPromises = Object.values(identityClients).map((client) => {
    return client?.isLoggedIn()
  })
  const results = await Promise.all(isLoggedInPromises)
  return results.find((result) => Boolean(result))
}

/**
 * This should open the simplified login widget for the first available client,
 * since we don't want to open more widgets in the event that the user is logged in to
 * multiple Schibsted markets.
 */
export function showSimplifiedLoginWidget({ action }: { action: AfterLoginAction }) {
  const [firstClientKey, firstClient] = Object.entries(identityClients).find(([, client]) =>
    Boolean(client),
  ) as [SchibstedSSOMarket, Identity]

  if (!firstClient) {
    return false
  }
  const state = encodeAfterLoginParameters({
    action,
    schibstedSSOMarket: firstClientKey,
  })
  return firstClient.showSimplifiedLoginWidget({ state, scope: 'openid profile email phone' })
}
