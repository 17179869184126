import { useTranslation } from 'react-i18next'
import { Stack } from '@qasa/qds-ui'

import { BRAND_CONFIG } from '../../../brands/brand-configurations'
import { Form } from '../components/form'
import { StepNavigation } from '../components/step-navigation'
import { WizardStepWrapper } from '../components/step-wrapper'
import { UspBox } from '../components/usp-box'
import { ONE_MILLION, requiredFormField } from '../create-tenant-listing.utils'
import { FormInputWithUnitField } from '../fields/form-input-with-unit-field'
import { useWizardRouterContext } from '../wizard-router/wizard-router'
import { FormSelectField } from '../fields/form-select-field'
import { getCurrencySymbol } from '../../../utils/misc'

export function RentStep() {
  const { goNext } = useWizardRouterContext()
  const { t } = useTranslation('create_tenant_listing')
  const { t: tUsp } = useTranslation('usp_sections')

  return (
    <WizardStepWrapper>
      <Form dataKey="rent" onSubmit={goNext}>
        {({ control, getValues, formState: { errors } }) => {
          return (
            <>
              <Stack direction={'row'} gap={'2x'}>
                <FormInputWithUnitField
                  name="maxMonthlyCost"
                  control={control}
                  label={t('fields.rent.title')}
                  unit={t('fields.rent.unit', {
                    currencySymbol: getCurrencySymbol({ currency: getValues('currency') }),
                  })}
                  placeholder={t('fields.rent.placeholder')}
                  rules={{ ...requiredFormField, max: ONE_MILLION }}
                  maxLength={7}
                  errorMessage={
                    errors['maxMonthlyCost'] && t(`fields.rent.error_${errors['maxMonthlyCost'].type}`)
                  }
                />
                {BRAND_CONFIG.allowedCurrencies.length > 1 && (
                  <FormSelectField
                    control={control}
                    options={BRAND_CONFIG.allowedCurrencies.map((currency) => ({
                      value: currency,
                      label: getCurrencySymbol({ currency }) ?? currency,
                    }))}
                    label={t('fields.currency.title')}
                    placeholder={t('fields.currency.placeholder')}
                    name={'currency'}
                    rules={requiredFormField}
                    errorMessage={errors['currency'] && t('fields.currency.required_error')}
                  />
                )}
              </Stack>
              <UspBox title={tUsp('deposit_free.title')} description={tUsp('deposit_free.description')} />
              <StepNavigation />
            </>
          )
        }}
      </Form>
    </WizardStepWrapper>
  )
}
