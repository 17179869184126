import { DropdownMenu, IconButton, MoreHorizontalIcon } from '@qasa/qds-ui'

type ActionsDropdownMenuProps = {
  items: { label: string; onClick: () => void }[]
  // NOTE: A quickfix to correctly de-mount the dropdown menu when opening the dialog.
  // Otherwise the body will have pointerEvents: none and the dropdown menu will not close
  // Can be removed when the dropdown menu is fixed.
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onSelect?: () => void
}
export function ActionsDropdownMenu({ items, isOpen, setIsOpen, onSelect }: ActionsDropdownMenuProps) {
  return (
    <DropdownMenu isOpen={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger
        as={IconButton}
        variant="tertiary"
        size="xs"
        icon={MoreHorizontalIcon}
        label="open-actions-menu"
      />
      <DropdownMenu.Content align="end" style={{ zIndex: 1500 }}>
        {items.map(({ label, onClick }) => (
          <DropdownMenu.Item
            key={label}
            onSelect={() => {
              onSelect?.()
              onClick()
            }}
          >
            {label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
