import { Stack, Divider } from '@qasa/qds-ui'
import { AnimatePresence } from 'framer-motion'
import { TenantAdStatusEnum } from '@qasa/graphql'

import { BRAND_TOGGLES } from '../../brands/toggles'

import { ProfileCompletenessSection } from './profile-completeness-section'
import { ProfileFooterBlock } from './profile-footer-block'
import { ProfilePublishedListingsBlock } from './profile-published-listings-block'
import { ProfileStatisticsBlock } from './profile-statistics-block'
import { getIsProfileCompletenessLow } from './profile.utils'
import { ProfileHousingBlock } from './view-profile-sections/profile-housing-block'
import { ProfileOccupationBlock } from './view-profile-sections/profile-occupation-block'
import { ProfileAboutMeBlock } from './view-profile-sections/profile-about-me-block'
import { ProfilePreferredAmenitiesBlock } from './view-profile-sections/profile-preferred-amenities-block'
import { ProfileRequiredAmenitiesBlock } from './view-profile-sections/profile-required-amenities-block'
import { useProfileContext } from './profile-context'
import { ProfileSearchPreferenceBlock } from './view-profile-sections/profile-search-preference-block'

export function ProfileBody() {
  const { isOwnProfile, profile, matchingHome } = useProfileContext()
  const isInProfileMatchingView = Boolean(matchingHome)
  const isTenant = profile.tenant
  const isProfessional = profile.professional
  const isShortcutAgent = profile.proAgent
  const isOwnProfileAndTenant = isTenant && isOwnProfile
  const isOwnTenantProfileWithLowScore =
    isOwnProfileAndTenant && getIsProfileCompletenessLow({ user: profile })

  const isLandlordAndHasPublishedHomes = Boolean(
    profile.landlord && profile.publishedHomes && profile.publishedHomes.length,
  )

  const hasActivity = profile.applicationStats?.locales && profile.applicationStats.locales.length !== 0
  const isTenantAndHasActivity = hasActivity && BRAND_TOGGLES.profileStatisticsSectionEnabled && isTenant

  const hasPublishedTenantAd = profile.tenantAd?.status === TenantAdStatusEnum.published

  return (
    <>
      <AnimatePresence>{isOwnTenantProfileWithLowScore && <ProfileCompletenessSection />}</AnimatePresence>
      <Stack gap="12x">
        <ProfileAboutMeBlock />
        {(isTenant || hasPublishedTenantAd) && (
          <>
            <ProfileSearchPreferenceBlock />
            <ProfileRequiredAmenitiesBlock />
            <ProfilePreferredAmenitiesBlock />
          </>
        )}
        {!isProfessional && !isShortcutAgent && <ProfileOccupationBlock />}
        {isTenant && <ProfileHousingBlock />}
        {!isInProfileMatchingView && (isLandlordAndHasPublishedHomes || isTenantAndHasActivity) && (
          <>
            <Divider />
            {isLandlordAndHasPublishedHomes && <ProfilePublishedListingsBlock />}
            {isTenantAndHasActivity && <ProfileStatisticsBlock />}
          </>
        )}
        {!isOwnProfile && <Divider />}
        <ProfileFooterBlock />
      </Stack>
    </>
  )
}
