import { Heading, Stack, ArrowRightIcon, Paragraph } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { BRAND_CONFIG } from '../../../../../brands/brand-configurations'

const IconContainer = styled.div({
  flexShrink: 0,
})

export function HowItWorksSection() {
  const { t } = useTranslation('listing')

  const listItems: string[] = t('find_tenant_intro.how_it_works.list', {
    returnObjects: true,
    brandName: BRAND_CONFIG.name,
  })

  return (
    <Stack gap="3x">
      <Heading size="xs">{t('find_tenant_intro.how_it_works.title')}</Heading>
      <Stack gap="1x">
        {listItems.map((item) => (
          <Stack direction="row" alignItems="center" gap="1x" key={item}>
            <IconContainer>
              <ArrowRightIcon size={16} strokeWidth={1.5} />
            </IconContainer>
            <Paragraph>{item}</Paragraph>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
