import { useEffect, useState } from 'react'

import { useFindHomeFiltersContext } from '../../../contexts'
import { useAppTranslation } from '../../../../../contexts/i18next'
import { useDebouncedValue } from '../../../../../hooks'
import { formatNumber } from '../../../../../utils/number'
import { RangeSlider } from '../../../../../components/range-slider'
import { useLocaleConfig } from '../../../../../configs/use-locale-config'

type Maybe<T> = T | null | undefined
type NightlyCostTuple = [Maybe<number>, Maybe<number>]

// TODO: This could probably be merged with rent filter section
export function NightlyCostFilterSection() {
  const { currency } = useLocaleConfig()
  const defaultMinNightlyCost = 0
  const defaultMaxNightlyCost = currency === 'EUR' ? 500 : 5000
  const stepSize = currency === 'EUR' ? 5 : 50

  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()
  const { t } = useAppTranslation('filter_sections')
  const { minNightlyCost, maxNightlyCost } = filterValues

  const [sliderValue, setSliderValue] = useState<NightlyCostTuple>([minNightlyCost, maxNightlyCost])
  const debouncedFilterValue = useDebouncedValue({ value: sliderValue, delay: 300 })

  useEffect(() => {
    const [minCost, maxCost] = debouncedFilterValue
    const sanitizedMin = minCost === defaultMinNightlyCost ? null : minCost
    const sanitizedMax = maxCost === defaultMaxNightlyCost ? null : maxCost

    updateFilterValues({ minNightlyCost: sanitizedMin, maxNightlyCost: sanitizedMax })
  }, [defaultMaxNightlyCost, debouncedFilterValue, updateFilterValues])

  // If value changes by clicking the clear button we need to manually update the slider value
  useEffect(() => {
    setSliderValue([minNightlyCost, maxNightlyCost])
  }, [minNightlyCost, maxNightlyCost])

  const handleFormatValue = (value: number) => {
    const formattedValue = formatNumber({ amount: value, currency })
    if (value === defaultMaxNightlyCost) {
      return formattedValue + '+'
    }
    return formattedValue
  }

  return (
    <RangeSlider
      label={t('nightly_cost.price_per_night')}
      value={[sliderValue[0] || defaultMinNightlyCost, sliderValue[1] || defaultMaxNightlyCost]}
      onChange={setSliderValue}
      step={stepSize}
      min={defaultMinNightlyCost}
      max={defaultMaxNightlyCost}
      formatValue={handleFormatValue}
      minRange={10}
    />
  )
}
