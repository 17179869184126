import { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button, GlobeIcon, Stack } from '@qasa/qds-ui'
import type { IconProps } from '@qasa/qds-ui'
import { Dialog } from '@qasa/app/src/components/dialog'

import { BRAND_CONFIG } from '../../brands/brand-configurations'
import { useCurrentLocale } from '../use-current-locale'
import { useChangeLocale } from '../use-change-locale'

import { AVAILABLE_LOCALES } from './language-utils'

const SwitchButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  height: 48,
  flexShrink: 0,
})

const LanguageButton = Button

type SwitchVariant = 'footer' | 'default'

export function LanguageSwitch({
  variant = 'default',
  className,
  iconSize = 20,
}: {
  variant?: SwitchVariant
  className?: string
  iconSize?: IconProps['size']
}) {
  const [isLanguageDialogOpen, setLanguageDialogOpen] = useState(false)
  const closeDialog = useCallback(() => setLanguageDialogOpen(false), [])
  const { t } = useTranslation('language_switch')
  const locale = useCurrentLocale()
  const changeLocale = useChangeLocale()

  if (BRAND_CONFIG.allowedLanguages.length < 2) {
    return null
  }

  const currentLanguage = AVAILABLE_LOCALES.find(({ languageCode }) => languageCode === locale)

  if (!currentLanguage) {
    return null
  }

  return (
    <Dialog
      isOpen={isLanguageDialogOpen}
      onOpenChange={(newValue) => {
        if (!newValue) {
          closeDialog()
        }
      }}
      trigger={
        variant === 'footer' ? (
          <Button variant="tertiary" onClick={() => setLanguageDialogOpen(true)} {...{ className }}>
            <Stack direction="row" alignItems="center" gap={'2x'}>
              <GlobeIcon size={iconSize} />
              {currentLanguage.nativeName}
            </Stack>
          </Button>
        ) : (
          <SwitchButton onClick={() => setLanguageDialogOpen(true)} {...{ className }}>
            <Stack direction="row" alignItems="center" gap={'4x'}>
              <GlobeIcon size={iconSize} />
              {currentLanguage.nativeName}
            </Stack>
          </SwitchButton>
        )
      }
    >
      <Dialog.Body>
        <Stack gap={'6x'} alignItems={'flex-start'}>
          <Dialog.Header>
            <Dialog.Title>{t('title')}</Dialog.Title>
          </Dialog.Header>
          <Stack gap={'4x'} alignItems={'flex-start'}>
            {AVAILABLE_LOCALES.filter(({ languageCode: code }) =>
              BRAND_CONFIG.allowedLanguages.includes(code),
            ).map(({ nativeName: name, languageCode: code }) => (
              <LanguageButton variant="tertiary" key={code} onClick={() => changeLocale(code)}>
                {name}
              </LanguageButton>
            ))}
          </Stack>
        </Stack>
      </Dialog.Body>
    </Dialog>
  )
}
