import type { ChangeEvent } from 'react'
import { useState, useEffect } from 'react'
import { Select, Stack } from '@qasa/ui'

import { useAppTranslation } from '../../../../../contexts/i18next'
import { useDebouncedValue } from '../../../../../hooks'
import { useFindHomeFiltersContext } from '../../../contexts'
import { CURRENT_PLATFORM } from '../../../../../env'
import { HomeFilterRangeSlider } from '../../components/home-filter-range-slider'

const MIN_ROOM_COUNT = 1
const MAX_ROOM_COUNT = 10
const MIN_SQUARE_METERS = 5
const MAX_SQUARE_METERS = 200

type Maybe<T> = T | null | undefined
type MonthlyCostTuple = [Maybe<number>, Maybe<number>]

const getIsWebChangeEvent = (
  value: string | ChangeEvent<HTMLSelectElement>,
): value is ChangeEvent<HTMLSelectElement> => CURRENT_PLATFORM === 'web'

export function HomeSizeFilterSection() {
  const {
    filterValues: { minRoomCount, maxRoomCount, householdSize, minSquareMeters, maxSquareMeters },
    updateFilterValues,
  } = useFindHomeFiltersContext()
  const { t } = useAppTranslation('filter_fields')

  const [sliderValueRoomCount, setSliderValueRoomCount] = useState<MonthlyCostTuple>([
    minRoomCount,
    maxRoomCount,
  ])
  const [sliderValueSquareMeters, setSliderValueSquareMeters] = useState<MonthlyCostTuple>([
    minSquareMeters,
    maxSquareMeters,
  ])

  const debouncedFilterValue = useDebouncedValue({ value: sliderValueRoomCount, delay: 300 })
  const debouncedFilterValue2 = useDebouncedValue({ value: sliderValueSquareMeters, delay: 300 })

  useEffect(() => {
    const [minRoomCount, maxRoomCount] = debouncedFilterValue
    const sanitizedMin = minRoomCount === MIN_ROOM_COUNT ? null : minRoomCount
    const sanitizedMax = maxRoomCount === MAX_ROOM_COUNT ? null : maxRoomCount

    updateFilterValues({ minRoomCount: sanitizedMin, maxRoomCount: sanitizedMax })
  }, [debouncedFilterValue, updateFilterValues])

  useEffect(() => {
    const [minSquareMeters, maxSquareMeters] = debouncedFilterValue2
    const sanitizedMin = minSquareMeters === MIN_SQUARE_METERS ? null : minSquareMeters
    const sanitizedMax = maxSquareMeters === MAX_SQUARE_METERS ? null : maxSquareMeters

    updateFilterValues({ minSquareMeters: sanitizedMin, maxSquareMeters: sanitizedMax })
  }, [debouncedFilterValue2, updateFilterValues])

  // If value changes by clicking the clear button we need to manually update the slider value
  useEffect(() => {
    setSliderValueRoomCount([minRoomCount, maxRoomCount])
  }, [minRoomCount, maxRoomCount])

  // If value changes by clicking the clear button we need to manually update the slider value
  useEffect(() => {
    setSliderValueSquareMeters([minSquareMeters, maxSquareMeters])
  }, [minSquareMeters, maxSquareMeters])

  const minHouseholdSizeOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8 +' },
  ]

  const handleFormatRooms = (number: number) => {
    if (number === MAX_ROOM_COUNT) {
      return number + '+'
    }
    return number.toString()
  }

  const handleFormatSquareMeters = (number: number) => {
    if (number === MAX_SQUARE_METERS) {
      return t('common_home:home_info.square_meters', { amount: `${number}+ ` })
    }
    return t('common_home:home_info.square_meters', { amount: number })
  }

  const handleHouseHoldSizeChange = (value: ChangeEvent<HTMLSelectElement> | string) => {
    if (getIsWebChangeEvent(value)) {
      updateFilterValues({ householdSize: parseInt(value.target.value, 10) || null })
      return
    }
    updateFilterValues({ householdSize: parseInt(value, 10) || null })
  }

  return (
    <Stack gap="6x">
      <HomeFilterRangeSlider
        label={t('rooms.label')}
        value={[sliderValueRoomCount[0] || MIN_ROOM_COUNT, sliderValueRoomCount[1] || MAX_ROOM_COUNT]}
        onChange={setSliderValueRoomCount}
        min={MIN_ROOM_COUNT}
        max={MAX_ROOM_COUNT}
        minRange={0}
        formatValue={handleFormatRooms}
      />
      <HomeFilterRangeSlider
        label={t('square_meters.label')}
        value={[
          sliderValueSquareMeters[0] || MIN_SQUARE_METERS,
          sliderValueSquareMeters[1] || MAX_SQUARE_METERS,
        ]}
        onChange={setSliderValueSquareMeters}
        min={MIN_SQUARE_METERS}
        max={MAX_SQUARE_METERS}
        step={5}
        formatValue={handleFormatSquareMeters}
      />
      <Select
        label={t('min_household_size.label')}
        placeholder={t('common:show_all')}
        value={householdSize?.toString() ?? undefined}
        options={minHouseholdSizeOptions.map(({ value, label }) => ({ value: value.toString(), label }))}
        onChange={handleHouseHoldSizeChange}
      />
    </Stack>
  )
}
