import type { ReactNode } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useToastContext } from '@qasa/app'
import { EditDialog } from '@qasa/app/src/modules/edit-dialog'

import { useProfileContext } from '../profile-context'
import type {
  ProfileSearchPreferenceFormValuesKeys,
  ProfileSearchPreferenceFormValues,
} from '../profile.types'
import { getProfileName } from '../profile.utils'
import { useEditSearchPreference } from '../hooks/use-edit-search-preference'
import { BRAND_CONFIG } from '../../../brands/brand-configurations'

type MaybeRenderProp<TDataKey extends ProfileSearchPreferenceFormValuesKeys> =
  | ((methods: UseFormReturn<ProfileSearchPreferenceFormValues[TDataKey]>) => ReactNode)
  | ReactNode

type ProfileSearchPreferenceFormProps<TDataKey extends ProfileSearchPreferenceFormValuesKeys> = {
  children?: MaybeRenderProp<TDataKey>
  dataKey: TDataKey
}
export function ProfileSearchPreferenceForm<TDataKey extends ProfileSearchPreferenceFormValuesKeys>({
  children,
  dataKey,
}: ProfileSearchPreferenceFormProps<TDataKey>) {
  const { profile, parsedSearchPreference, closeEditProfileSection, activeEditSection, isEditDrawerOpen } =
    useProfileContext()
  const { t } = useTranslation('edit_profile')
  const { addToast } = useToastContext()
  const handleError = () => addToast({ message: t('commons:unexpected_error') })

  const { requiredAmenities, preferredAmenities, currency, ...rest } = parsedSearchPreference
  const allDefaultValues: ProfileSearchPreferenceFormValues = {
    requiredAmenities: { requiredAmenities },
    preferredAmenities: { preferredAmenities },
    searchPreference: {
      currency: currency ?? BRAND_CONFIG.allowedCurrencies[0],
      ...rest,
    },
  }
  const currentSectionDefaultValues = allDefaultValues[dataKey]
  const { formMethods, onSubmitSearchPreference } = useEditSearchPreference({
    onCompleted: closeEditProfileSection,
    onError: handleError,
    defaultValues: currentSectionDefaultValues,
  })

  return (
    <EditDialog
      isOpen={isEditDrawerOpen}
      onOpenChange={closeEditProfileSection}
      title={t(`profile:sections_title.${activeEditSection}`, {
        profileName: getProfileName({ user: profile }),
      })}
      formMethods={formMethods}
      formId="profile-search-preference-form"
      onSubmit={onSubmitSearchPreference}
    >
      {children}
    </EditDialog>
  )
}
