import { CountryCodeEnum } from '@qasa/graphql'

import type { LanguageCode } from '../../helpers/i18n'
import { BRAND_CONFIG } from '../../brands/brand-configurations'

export const AVAILABLE_LOCALES: {
  /**
   * Language code in ISO 639-1 format.
   */
  languageCode: LanguageCode
  /**
   * Locale code in IETF format.
   * Combination of a language code (ISO 639) and a country code (ISO 3166-1).
   * @see https://en.wikipedia.org/wiki/IETF_language_tag
   */
  ietfCode: string
  nativeName: string
}[] = [
  {
    languageCode: 'sv',
    ietfCode: 'sv-SE',
    nativeName: 'Svenska',
  },
  {
    languageCode: 'en',
    ietfCode: 'en-GB',
    nativeName: 'English',
  },
  {
    languageCode: 'fi',
    ietfCode: 'fi-FI',
    nativeName: 'Suomi',
  },
  {
    languageCode: 'fr',
    ietfCode: 'fr-FR',
    nativeName: 'Français',
  },
]
const LANGUAGE_CODES = AVAILABLE_LOCALES.map(({ languageCode: code }) => code)

function isLanguageCode(language: string): language is LanguageCode {
  // @ts-expect-error
  return LANGUAGE_CODES.includes(language)
}

/**
 * This function is not meant to be used in components outside of the language loader related logic.
 * For other cases use the `useCurrentLocale` hook.
 */
export function getCurrentLocale(location = window.location) {
  const locale = location.pathname.split('/')[1]
  if (isLanguageCode(locale)) {
    return locale as LanguageCode
  }

  // It should never end up here since our middleware should always redirect to a valid language.
  // But as a precaution we return the default language.
  return BRAND_CONFIG.defaultLanguage
}

export function getCountries() {
  const regionNames = new Intl.DisplayNames(getCurrentLocale(), { type: 'region' })
  return Object.values(CountryCodeEnum)
    .map((code) => ({
      code,
      name: regionNames.of(code) as string,
    }))
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1
      }

      if (a.name < b.name) {
        return -1
      }

      return 0
    })
}
