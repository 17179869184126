import { apolloClient, gtmCall, reportError } from '@qasa/app'
import type { PlatformEnum } from '@qasa/graphql'
import { SchibstedAccountEnvironmentEnum } from '@qasa/graphql'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useRef } from 'react'

import { currentBrand } from '../brands'
import type { SchibstedSSOMarket } from '../brands/index.types'
import { useAuthContext } from '../context/auth-context'
import { SCHIBSTED_LOGIN } from '../data/graphql/mutations'
import { getPath } from '../routing/get-path'
import { useCreateHome } from '../ui-page-modules/listing/use-create-home'
import { useRouter } from '../vendor/next'
import { getRedirectUrl, logout } from '../vendor/schibsted-identity-client'

import type { AfterLoginAction, SignupMode } from './auth-callback.types'
import { AFTER_LOGIN_ACTIONS } from './auth-callback.types'

export const AFTER_LOGIN_ACTION_COOKIE_NAME = 'AfterLoginAction'

type AfterLoginParameters = {
  action: AfterLoginAction
  schibstedSSOMarket?: SchibstedSSOMarket
}
export function encodeAfterLoginParameters({ action, schibstedSSOMarket }: AfterLoginParameters) {
  return encodeURIComponent(window.btoa(JSON.stringify({ action, schibstedSSOMarket })))
}

export function decodeAfterLoginParameters(parameters: string): AfterLoginParameters {
  const base64DecodedParams = window.atob(decodeURIComponent(parameters))

  try {
    const decodedParameters = JSON.parse(base64DecodedParams)
    return {
      action: decodedParameters.action,
      schibstedSSOMarket: decodedParameters.schibstedSSOMarket,
    }
  } catch (error) {
    // This shouldn't happen but for some reason it does https://qasa.sentry.io/issues/5489031216/events/7ad6006dd93343c5a495207aaefe3d11/events/?project=5628982
    // it seems like we in some rare cases base64 encode an already URI encoded string, instead of the other way around.
    // Adding this as a hotfix because it's currently preventing the affected (albiet few) users from logging in
    // if the sentry reporting subsides we should be good to remove it again
    reportError('Invalid base64 encoded after login parameters', { state: base64DecodedParams })
    const decodedParameters = JSON.parse(decodeURIComponent(base64DecodedParams))
    return {
      action: decodedParameters.action,
      schibstedSSOMarket: decodedParameters.schibstedSSOMarket,
    }
  }
}

export function createAfterLoginActionParam(action: AfterLoginAction) {
  return 'state=' + encodeAfterLoginParameters({ action })
}

const setAfterLoginCookie = (action: AfterLoginAction) => {
  Cookies.set(AFTER_LOGIN_ACTION_COOKIE_NAME, encodeAfterLoginParameters({ action }), { expires: 1 })
}

export function useAuthenticatedAction() {
  const { push } = useRouter()

  const pushToLoginWithAfterAction = useCallback(
    (action: AfterLoginAction) => {
      setAfterLoginCookie(action)
      push(getPath('login') + '?' + createAfterLoginActionParam(action))
    },
    [push],
  )

  const pushToSignupWithAfterAction = useCallback(
    (action: AfterLoginAction, mode?: SignupMode) => {
      setAfterLoginCookie(action)
      const modeParam = mode ? `&mode=${mode}` : ''
      push(getPath('signup') + '?' + createAfterLoginActionParam(action) + modeParam)
    },
    [push],
  )
  return { pushToLoginWithAfterAction, pushToSignupWithAfterAction }
}

export const getAfterLoginActionCookie = (): AfterLoginAction | null => {
  const cookie = Cookies.get(AFTER_LOGIN_ACTION_COOKIE_NAME)

  if (cookie) {
    return decodeAfterLoginParameters(cookie).action
  }

  return null
}

type UseAuthCallbackHandlerParams = {
  afterLoginAction: AfterLoginAction
  schibstedSSOMarket?: SchibstedSSOMarket
}
export function useAuthCallbackHandler({
  afterLoginAction: afterLoginActionParam,
  schibstedSSOMarket,
}: UseAuthCallbackHandlerParams) {
  const { push, replace } = useRouter()
  const { createHome } = useCreateHome()

  const { isAuthenticated, setAccessTokenAndFetchUser } = useAuthContext()
  // Store the after login action in a ref since
  // we don't want to react to it in the useEffect
  const afterLoginActionRef = useRef(afterLoginActionParam)

  type HandleLoginWithSchibstedParams = {
    authorizationCode: string
  }
  const handleLoginWithSchibsted = useCallback(
    async ({ authorizationCode }: HandleLoginWithSchibstedParams) => {
      const afterLoginAction = afterLoginActionRef.current
      const isInCreateAdFlow = afterLoginAction.type === AFTER_LOGIN_ACTIONS.CREATE_HOME
      const isLandlord = isInCreateAdFlow

      const { data, errors } = await apolloClient.mutate({
        mutation: SCHIBSTED_LOGIN,
        variables: {
          input: {
            accountEnvironment:
              schibstedSSOMarket === 'schibsted_se'
                ? SchibstedAccountEnvironmentEnum.com
                : SchibstedAccountEnvironmentEnum.fi,
            mainPlatform: currentBrand as PlatformEnum,
            authorizationCode,
            redirectUrl: getRedirectUrl(),
            landlord: isLandlord,
            ...(afterLoginAction.type === AFTER_LOGIN_ACTIONS.CREATE_HOME && {
              professional: afterLoginAction.user.isProfessional,
              companyName: afterLoginAction.user.companyName,
              orgNumber: afterLoginAction.user.orgNumber,
            }),
          },
        },
      })

      if (errors || data?.schibstedLogin?.errors || !data?.schibstedLogin?.payload) {
        const errorReportArgs = errors ?? data!.schibstedLogin!.errors!
        reportError('Schibsted login error', { errors: JSON.stringify(errorReportArgs) })
        logout({ redirectUri: window.location.origin, market: schibstedSSOMarket })
      } else {
        const { user, accessToken } = data.schibstedLogin.payload
        setAccessTokenAndFetchUser(accessToken)
        gtmCall({
          event: 'USER_LOGIN',
          payload: { schibstedAccountId: user.private.schibstedAccountId },
        })
      }
    },
    [setAccessTokenAndFetchUser, schibstedSSOMarket],
  )

  type AuthCallbackHandlerParams = {
    authorizationCode?: string
  }
  const handleAuthCallback = useCallback(
    async ({ authorizationCode }: AuthCallbackHandlerParams) => {
      // If we have an authorization code, we are in the callback from Schibsted
      if (authorizationCode) {
        await handleLoginWithSchibsted({ authorizationCode })
      }
    },
    [handleLoginWithSchibsted],
  )

  const handleAfterLoginAction = useCallback(async () => {
    const afterLoginAction = afterLoginActionRef.current
    switch (afterLoginAction.type) {
      case AFTER_LOGIN_ACTIONS.CREATE_HOME:
        await createHome({
          input: afterLoginAction.input,
          user: afterLoginAction.user,
          isFromFindTenant: afterLoginAction.isFromFindTenant,
        })
        break
      case AFTER_LOGIN_ACTIONS.GO_TO_ROUTE:
        // NOTE: in some cases the search param includes the question mark already, this solution should work for old cases
        // after the updates done to make it work for next
        replace(`${afterLoginAction.route}?${afterLoginAction.search?.replace('?', '') || ''}`)
        break
      default:
        // As the user is already authenticated, they will not really go to the login page but to the default authenticated page
        push(getPath('login'))
    }
  }, [createHome, replace, push])

  useEffect(() => {
    if (isAuthenticated) {
      // If we were to run this function directly after setting the auth body in `handleAuthCallback`
      // the after login handlers gets called with no authenticated user yet.
      // Therefore we run it in a useEffect to be able to trigger it first when
      // the state update has propagated.
      handleAfterLoginAction()
      Cookies.remove(AFTER_LOGIN_ACTION_COOKIE_NAME)
    }
  }, [isAuthenticated, handleAfterLoginAction])

  return { handleAuthCallback }
}
