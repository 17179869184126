import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import type { FindHomeFilterValues } from '@qasa/app'
import { useFindHomeContext, useFindHomeFiltersContext } from '@qasa/app'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import type { HomeTypeEnum } from '@qasa/graphql'

import { MetaTags } from '../meta-tags'
import { capitalize } from '../../utils/string'
import { useBrandDefaultCanonicalUrl } from '../../brands/canonical-url'
import { BRAND_CONFIG } from '../../brands/brand-configurations'

type FormatPageTitleProps = {
  filterValues: FindHomeFilterValues
  isVacationHomeSearch: boolean
  count: number
}
const useFormatPageTitle = ({ filterValues, isVacationHomeSearch, count }: FormatPageTitleProps) => {
  const { t } = useTranslation('find_home_metatag')
  const { homeTypes, searchAreas } = filterValues
  const prefix = isVacationHomeSearch ? 'page_title_vacation.' : 'page_title.'

  let title = ''
  if (!homeTypes && !searchAreas) {
    title = t(prefix + 'find_home', { count })
  }

  const translatedHomeType = homeTypes.length === 1 && t('commons:home_type:' + homeTypes[0])
  const capitalizedArea = searchAreas.length === 1 && capitalize(searchAreas[0].name)

  if (translatedHomeType && capitalizedArea) {
    title = t(prefix + 'find_homeType_in_area', {
      homeType: translatedHomeType,
      area: capitalizedArea,
      count,
    })
  } else if (translatedHomeType) {
    title = t(prefix + 'find_homeType', { homeType: translatedHomeType, count })
  } else if (capitalizedArea) {
    title = t(prefix + 'find_home_in_area', {
      area: capitalizedArea,
      count,
    })
  } else {
    title = t(prefix + 'find_home', { count })
  }

  return title
}

type FormatPageDescriptionArgs = {
  count: number
  homeType?: HomeTypeEnum
  location?: string
  isVacationHomeSearch: boolean
}

const useFormatPageDescription = ({
  count,
  homeType,
  location,
  isVacationHomeSearch,
}: FormatPageDescriptionArgs) => {
  const { t } = useTranslation('find_home_metatag')
  const prefix = isVacationHomeSearch ? 'page_descriptions_vacation' : 'page_descriptions'
  let translationKey = `${prefix}.description`

  if (homeType && location) {
    translationKey = `${prefix}.description_with_location_with_home_type`
  } else if (homeType) {
    translationKey = `${prefix}.description_with_home_type`
  } else if (location) {
    translationKey = `${prefix}.description_with_location`
  }

  const translatedHomeType = t(`home_type.${homeType}_plural`, { ns: 'commons' }).toLowerCase()
  return t(translationKey, {
    count,
    location,
    homeType: translatedHomeType,
    brandName: BRAND_CONFIG.name,
  })
}

export function FindHomeMetaTags() {
  const { filterValues } = useFindHomeFiltersContext()
  const { homesResultCount, isHomesLoading, areas, rentalType } = useFindHomeContext()
  const count = homesResultCount || 0

  const homeType = filterValues?.homeTypes?.[0] as HomeTypeEnum
  const location = filterValues.searchAreas[0]?.name

  const isVacationHomeSearch = rentalType === HomeRentalTypeEnum.vacation

  const { search } = useLocation()

  const parsedSearch = parse(search)
  const indexableQueryParams: (keyof FindHomeFilterValues)[] = ['homeTypes', 'searchAreas', 'page']
  const isActiveFiltersNotIndexable = Object.keys(parsedSearch).some((key) => {
    const isIndexable = indexableQueryParams.includes(key as keyof FindHomeFilterValues)
    return !isIndexable
  })

  //Conditions for when find-home shouldn't be indexable by crawlers - Fredrik Berntsson 2021-05-24
  const hasMultipleHomeTypes = filterValues?.homeTypes.length > 1
  const hasMultipleAreas = areas.length > 1
  const hasZeroCount = count === 0

  const shouldNotIndex =
    hasMultipleHomeTypes ||
    hasMultipleAreas ||
    (!isHomesLoading && hasZeroCount) ||
    isActiveFiltersNotIndexable

  const pageTitle = useFormatPageTitle({ filterValues, isVacationHomeSearch, count })
  const pageDescription = useFormatPageDescription({ count, homeType, location, isVacationHomeSearch })
  /* Include query params, unlike other pages' canonicals */
  const canonicalUrl = useBrandDefaultCanonicalUrl() + search

  return (
    <MetaTags
      title={pageTitle}
      description={pageDescription}
      noIndex={shouldNotIndex}
      canonicalUrl={canonicalUrl}
    />
  )
}
