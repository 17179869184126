import type { BigSearchLongtermCardFragment } from '@qasa/graphql'

import {
  getHomeTagVariant,
  getFormattedAddress,
  useHomeBaseInfo,
  getFormattedMonthlyCost,
} from '../../../../utils/home'
import { getSortedUploads, prependSrcWithThumbor } from '../../../../utils/image'
import { HOME_PLACEHOLDER_IMAGE_SRC } from '../../../../assets/home-placeholder'

type UseBigSearchLongtermCardParams = {
  data: BigSearchLongtermCardFragment
}

export function useBigSearchLongtermCard({ data }: UseBigSearchLongtermCardParams) {
  const {
    location,
    uploads,
    firsthand: isFirsthand,
    user: { proAgent: isShortcutHome },
    instantSignSetting,
    duration,
    favoriteMarkedByUser: isFavoriteMarked,
    displayStreetNumber: shouldDisplayStreetNumber,
  } = data
  const { mainBullets } = useHomeBaseInfo(data)
  const isInstantSign = Boolean(instantSignSetting?.enabled)

  const formattedImages = uploads.length
    ? getSortedUploads({ uploads }).map(({ url }) =>
        prependSrcWithThumbor({ src: url, height: 350, width: 350 }),
      )
    : [HOME_PLACEHOLDER_IMAGE_SRC]

  const homeTagVariant = getHomeTagVariant({ isInstantSign, isShortcutHome, isFirsthand })

  const formattedRent = getFormattedMonthlyCost({ home: data })

  const heading = getFormattedAddress({ location, shouldDisplayStreetNumber })

  return {
    images: formattedImages,
    hero: formattedRent,
    heading,
    homeTagVariant,
    duration,
    bulletData: mainBullets,
    isFavoriteMarked,
  }
}
