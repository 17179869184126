import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { HintBox, Stack } from '@qasa/qds-ui'
import { Autocomplete } from '@qasa/app/src/components/autocomplete/web'
import { useAreaSuggestions } from '@qasa/app/src/modules/area-search'
import { HomeTypeEnum, TenantAdStatusEnum } from '@qasa/graphql'

import { FormRadioGroup } from '../../../ui-shared/forms/form-radio-group'
import { FieldWithHint } from '../../create-tenant-listing/components/field-with-hint'
import {
  requiredFormField,
  validateMoveInAfterMoveOut,
  firstValidMoveOutDate,
  isDayBeforeValidMoveOutDate,
  ONE_MILLION,
} from '../../create-tenant-listing/create-tenant-listing.utils'
import { FormCheckboxGroup } from '../../create-tenant-listing/fields/form-checkbox-group'
import { FormDurationInputsField } from '../../create-tenant-listing/fields/form-duration-inputs-field'
import { FormInputWithUnitField } from '../../create-tenant-listing/fields/form-input-with-unit-field'
import { FormSelectField } from '../../create-tenant-listing/fields/form-select-field'
import { useProfileContext } from '../profile-context'
import { ProfileContractTypeEnum, ProfileFurnishedEnum, ProfileSharedHomeEnum } from '../profile.types'
import { ProfileSearchPreferenceForm } from '../forms/profile-search-preference-form'
import { getCurrencySymbol } from '../../../utils/misc'
import { BRAND_CONFIG } from '../../../brands/brand-configurations'

const HOME_TYPES = [
  HomeTypeEnum.apartment,
  HomeTypeEnum.house,
  HomeTypeEnum.terrace_house,
  HomeTypeEnum.duplex,
  HomeTypeEnum.other,
]

const DurationWrapper = styled.div({
  '.SingleDatePicker_picker': {
    zIndex: 20,
  },
})

export function SearchPreferenceSection() {
  const { tenantAdStatus } = useProfileContext()
  const { t } = useTranslation('create_tenant_listing')
  const [searchQuery, setSearchQuery] = useState('')
  const { isLoadingSuggestions, suggestedAreas } = useAreaSuggestions({
    searchQuery,
    suggestionsLimit: 8,
  })

  const isProfilePublished = TenantAdStatusEnum.published === tenantAdStatus
  const requiredOnlyIfPublished = isProfilePublished ? requiredFormField : {}

  const homeTypeOptions = HOME_TYPES.map((homeType) => {
    return { value: homeType, label: t(`fields.home_type.checkbox_options.${homeType}`) }
  })
  const sharedOptions = Object.values(ProfileSharedHomeEnum).map((value) => ({
    value,
    label: t(`fields.shared_home.${value}`),
  }))
  const furnitureOptions = Object.values(ProfileFurnishedEnum).map((value) => ({
    value,
    label: t(`fields.furniture.${value}`),
  }))
  const contractTypesOptions = Object.values(ProfileContractTypeEnum).map((value) => ({
    value,
    label: t(`fields.contract_types.${value}`),
  }))
  const numberOfTenantsOptions = [1, 2, 3, 4, 5, 6, 7].map((value) => {
    return { value, label: value.toString() }
  })
  const numberOfRoomsOptions = [1, 2, 3, 4, 5, 6, 7].map((value) => {
    return { value, label: value + '+' }
  })

  return (
    <ProfileSearchPreferenceForm dataKey="searchPreference">
      {({ control, watch, getValues, formState: { errors } }) => {
        const currencyValue = watch('currency')
        return (
          <Stack gap="8x">
            <Controller
              name="selectedAreas"
              control={control}
              rules={requiredOnlyIfPublished}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  placeholder={t('fields.location.input_placeholder')}
                  label={t('fields.location.input_label')}
                  onChange={onChange}
                  value={value}
                  optionToLabel={(item) => (item ? item.name : '')}
                  optionToSublabel={(option) => option?.city}
                  options={suggestedAreas}
                  maxOptions={3}
                  inputValue={searchQuery}
                  onInputValueChange={setSearchQuery}
                  isLoading={isLoadingSuggestions}
                  errorMessage={errors['selectedAreas'] && t('fields.location.required_error')}
                />
              )}
            />
            <Stack direction={'row'} gap={'2x'}>
              <FormInputWithUnitField
                name="maxMonthlyCost"
                control={control}
                label={t('fields.rent.title')}
                unit={
                  currencyValue
                    ? t('fields.rent.unit', {
                        currencySymbol: getCurrencySymbol({ currency: currencyValue }),
                      })
                    : ''
                }
                placeholder={t('fields.rent.placeholder')}
                rules={{ ...requiredOnlyIfPublished, max: ONE_MILLION }}
                maxLength={7}
                errorMessage={
                  errors['maxMonthlyCost'] && t(`fields.rent.error_${errors['maxMonthlyCost'].type}`)
                }
              />
              {BRAND_CONFIG.allowedCurrencies.length > 1 && (
                <FormSelectField
                  control={control}
                  options={BRAND_CONFIG.allowedCurrencies.map((currency) => ({
                    value: currency,
                    label: getCurrencySymbol({ currency }) ?? currency,
                  }))}
                  label={t('fields.currency.title')}
                  placeholder={t('fields.currency.placeholder')}
                  name={'currency'}
                  rules={requiredFormField}
                  errorMessage={errors['currency'] && t('fields.currency.required_error')}
                />
              )}
            </Stack>
            <DurationWrapper>
              <FormDurationInputsField
                name={'moveIn'}
                label="move_in"
                nonDateLabel="asap"
                control={control}
              />
            </DurationWrapper>
            <DurationWrapper>
              <FormDurationInputsField
                name={'moveOut'}
                label="move_out"
                nonDateLabel="until_further_notice"
                control={control}
                rules={{
                  validate: () =>
                    validateMoveInAfterMoveOut({
                      moveIn: getValues('moveIn'),
                      moveOut: getValues('moveOut'),
                    }),
                }}
                errorMessage={errors['moveOut'] && t('fields.dates.move_in_after_move_out_error')}
                isOutsideRange={isDayBeforeValidMoveOutDate}
                initialVisibleMonth={firstValidMoveOutDate}
              />
            </DurationWrapper>
            <FormSelectField
              control={control}
              options={numberOfTenantsOptions}
              label={t('fields.number_of_tenants.title')}
              placeholder={t('fields.number_of_tenants.placeholder')}
              name={'householdSize'}
              rules={requiredOnlyIfPublished}
              errorMessage={errors['householdSize'] && t('fields.number_of_tenants.required_error')}
            />
            <FormSelectField
              name={'minRoomCount'}
              control={control}
              label={t('fields.number_of_rooms.label')}
              placeholder={t('fields.number_of_rooms.placeholder')}
              options={numberOfRoomsOptions}
              rules={requiredOnlyIfPublished}
              errorMessage={errors['minRoomCount'] && t('fields.number_of_rooms.required_error')}
            />
            <FormInputWithUnitField
              label={t('fields.square_meters.label')}
              name={'minSquareMeters'}
              control={control}
              unit={t('commons:square_meters')}
              placeholder={t('fields.square_meters.placeholder')}
              rules={requiredOnlyIfPublished}
              errorMessage={errors['minSquareMeters'] && t('fields.square_meters.required_error')}
            />
            <FieldWithHint>
              <FormRadioGroup
                label={t('fields.shared_home.label')}
                name={'shared'}
                options={sharedOptions}
                control={control}
              />
              <HintBox>{t('fields.shared_home.hint_box')}</HintBox>
            </FieldWithHint>
            <FormRadioGroup
              label={t('fields.furniture.label')}
              name={'furniture'}
              options={furnitureOptions}
              control={control}
            />
            <FieldWithHint>
              <FormCheckboxGroup
                label={t('fields.home_type.label')}
                control={control}
                options={homeTypeOptions}
                name={'homeType'}
                rules={requiredOnlyIfPublished}
                errorMessage={errors['homeType'] && t('fields.home_type.required_error')}
              />
              <HintBox>{t('fields.home_type.hint_box')}</HintBox>
            </FieldWithHint>

            <FieldWithHint>
              <FormRadioGroup
                label={t('fields.contract_types.label')}
                name={'contractType'}
                options={contractTypesOptions}
                control={control}
              />
              <HintBox>{t('fields.contract_types.hint_box')}</HintBox>
            </FieldWithHint>
          </Stack>
        )
      }}
    </ProfileSearchPreferenceForm>
  )
}
