import type { ChangeEvent } from 'react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Divider, HintBox, Select, Stack, TextField } from '@qasa/qds-ui'
import { CountryCodeEnum } from '@qasa/graphql'
import { type CreateLocationInput } from '@qasa/graphql'

import { useListingContext } from '../listing-context'
import type { UpdateFieldValueAction } from '../edit-listing.types'
import { FieldEnum } from '../edit-listing.types'
import { SectionWrapper } from '../section-wrapper'
import { AddressAutoCompleteField } from '../address-autocomplete-field'
import { getAvailableCountryCodesForRentalType } from '../../../brands/markets'

import { useGoogleAutocomplete } from './use-google-autocomplete'

const Wrapper = styled.div(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing['8x'],
  columnGap: theme.spacing['4x'],
  gridTemplateColumns: '1fr',
  [theme.mediaQueries.mdUp]: {
    gridTemplateColumns: '1fr 1fr',
  },
}))

const COUNTRY_NAMES = {
  [CountryCodeEnum.FI]: 'Finland',
  [CountryCodeEnum.SE]: 'Sweden',
  [CountryCodeEnum.NO]: 'Norway',
  [CountryCodeEnum.FR]: 'France',
}
type SupportedCountryCodes = keyof typeof COUNTRY_NAMES

export function ListingLocationAddress() {
  const { t } = useTranslation('listing')

  const firstInputFieldRef = useRef<HTMLInputElement>(null)
  const {
    store: {
      values: {
        position: { latitude, longitude },
        route,
        streetNumber,
        apartmentNumber,
        countryCode,
        postalCode,
        locality,
        formattedAddress,
        floor,
        buildingFloors,
        rentalType,
      },
    },
    getErrorMessage,
    dispatch,
  } = useListingContext()
  const [hasInteractedWithAutocomplete, setHasInteractedWithAutocomplete] = useState(
    Boolean(latitude && longitude),
  )

  const handleInputValueChange = (inputValue?: string) =>
    dispatch({ action: { type: FieldEnum.FormattedAddress, payload: inputValue || '' } })
  const handleSelectedItemChange = (detailedLocation: google.maps.places.PlaceResult | null) => {
    setHasInteractedWithAutocomplete(true)
    if (detailedLocation) {
      dispatchMultipleValues({
        values: parseGoogleAutocompleteResponse({
          location: detailedLocation,
        }),
      })
    }
  }

  const { closeMenu, parseGoogleAutocompleteResponse, ...autocompleteProps } = useGoogleAutocomplete({
    formattedAddress,
    handleInputValueChange,
    handleSelectedItemChange,
    allowedCountries: getAvailableCountryCodesForRentalType({ rentalType }),
  })

  const dispatchMultipleValues = ({ values }: { values: Partial<CreateLocationInput> }) => {
    Object.keys(values).forEach((key) => {
      dispatch({
        action: {
          type: key,
          payload: values[key as keyof CreateLocationInput],
        } as UpdateFieldValueAction,
      })
    })
  }

  type HandleFieldChangeParams = {
    field:
      | FieldEnum.Locality
      | FieldEnum.PostalCode
      | FieldEnum.Route
      | FieldEnum.StreetNumber
      | FieldEnum.ApartmentNumber
    event: ChangeEvent<HTMLInputElement>
  }
  const handleFieldChange = ({ field, event }: HandleFieldChangeParams) => {
    dispatch({
      action: {
        type: field,
        payload: event.target.value,
      },
    })
  }

  const handleCountryChange = (countryCode: SupportedCountryCodes) => {
    dispatch({
      action: {
        type: FieldEnum.CountryCode,
        payload: countryCode,
      },
    })
    dispatch({
      action: {
        type: FieldEnum.Country,
        payload: COUNTRY_NAMES[countryCode],
      },
    })
  }

  const handleFloorFieldChange = (floor: string) => {
    dispatch({
      action: {
        type: FieldEnum.Floor,
        payload: floor ? parseInt(floor, 10) : null,
      },
    })
  }
  const handleBuildingFloorsFieldChange = (buildingFloors: string) => {
    dispatch({
      action: {
        type: FieldEnum.BuildingFloors,
        payload: buildingFloors ? parseInt(buildingFloors, 10) : null,
      },
    })
  }

  const clearAutocompleteField = () => {
    if (formattedAddress) {
      dispatch({ action: { type: FieldEnum.FormattedAddress, payload: '' } })
    }
  }
  const handleSelectManualInputOption = () => {
    setHasInteractedWithAutocomplete(true)
    closeMenu()
    firstInputFieldRef?.current?.select()
  }
  const hasAddress = route || streetNumber || postalCode || locality

  const routeErrorMessage = getErrorMessage({ field: FieldEnum.Route }) ?? undefined
  const streetNumberErrorMessage = getErrorMessage({ field: FieldEnum.StreetNumber }) ?? undefined
  const postalCodeErrorMessage = getErrorMessage({ field: FieldEnum.PostalCode }) ?? undefined
  const localityErrorMessage = getErrorMessage({ field: FieldEnum.Locality }) ?? undefined
  const apartmentNumberErrorMessage = getErrorMessage({ field: FieldEnum.ApartmentNumber }) ?? undefined
  const floorErrorMessage = getErrorMessage({ field: FieldEnum.Floor }) ?? undefined
  const buildingFloorsErrorMessage = getErrorMessage({ field: FieldEnum.BuildingFloors }) ?? undefined
  const countryCodeErrorMessage = getErrorMessage({ field: FieldEnum.CountryCode }) ?? undefined

  const availableCountriesForRentalType = getAvailableCountryCodesForRentalType({ rentalType })
  const hasMoreThanOneCountryForCurrentRentalType = availableCountriesForRentalType.length > 1

  const countryCodeOptions = availableCountriesForRentalType.map((countryCode) => (
    <Select.Option value={countryCode} key={countryCode}>
      {`${t(`commons:countries.${countryCode.toLowerCase()}`)} (${countryCode})`}
    </Select.Option>
  ))

  return (
    <SectionWrapper title={t('section_headings.address')} description={t('address.subheading')}>
      <AddressAutoCompleteField
        {...{
          ...autocompleteProps,
          closeMenu,
          formattedAddress,
          onSelectManualInputOption: handleSelectManualInputOption,
        }}
      />
      {(hasAddress || hasInteractedWithAutocomplete) && (
        <>
          <Divider orientation="horizontal" />
          <Wrapper>
            <TextField
              label={t('route.label')}
              ref={firstInputFieldRef}
              name={FieldEnum.Route}
              value={route || ''}
              onChange={(event) => handleFieldChange({ field: FieldEnum.Route, event })}
              onFocus={clearAutocompleteField}
              errorMessage={routeErrorMessage}
              isInvalid={Boolean(routeErrorMessage)}
            />
            <TextField
              label={t('house_number_or_address_row_2.label')}
              name={FieldEnum.StreetNumber}
              value={streetNumber || ''}
              onChange={(event) => handleFieldChange({ field: FieldEnum.StreetNumber, event })}
              onFocus={clearAutocompleteField}
              errorMessage={streetNumberErrorMessage}
              isInvalid={Boolean(streetNumberErrorMessage)}
            />
            <TextField
              label={t('postal_code.label')}
              name={FieldEnum.PostalCode}
              value={postalCode || ''}
              onChange={(event) => handleFieldChange({ field: FieldEnum.PostalCode, event })}
              onFocus={clearAutocompleteField}
              errorMessage={postalCodeErrorMessage}
              isInvalid={Boolean(postalCodeErrorMessage)}
            />
            <TextField
              label={t('city.label')}
              name={FieldEnum.Locality}
              value={locality || ''}
              onChange={(event) => handleFieldChange({ field: FieldEnum.Locality, event })}
              onFocus={clearAutocompleteField}
              errorMessage={localityErrorMessage}
              isInvalid={Boolean(localityErrorMessage)}
            />
            {hasMoreThanOneCountryForCurrentRentalType && (
              <Select
                label={t('country.label')}
                errorMessage={countryCodeErrorMessage}
                isInvalid={Boolean(countryCodeErrorMessage)}
                value={countryCode ?? undefined}
                placeholder={t('country.placeholder')}
                onChange={(e) => handleCountryChange(e.target.value as SupportedCountryCodes)}
              >
                {countryCodeOptions}
              </Select>
            )}
            <TextField
              label={t('floor.label')}
              name={FieldEnum.Floor}
              isOptional
              type="number"
              step={1}
              value={floor ?? undefined}
              onChange={(event) => handleFloorFieldChange(event.target.value)}
              errorMessage={floorErrorMessage}
              isInvalid={Boolean(floorErrorMessage)}
            />
            <TextField
              label={t('building_floors.label')}
              name={FieldEnum.BuildingFloors}
              isOptional
              type="number"
              step={1}
              value={buildingFloors ?? undefined}
              onChange={(event) => handleBuildingFloorsFieldChange(event.target.value)}
              errorMessage={buildingFloorsErrorMessage}
              isInvalid={Boolean(buildingFloorsErrorMessage)}
            />
            <TextField
              label={t('apartment_number.label')}
              name={FieldEnum.ApartmentNumber}
              isOptional
              value={apartmentNumber || ''}
              onChange={(event) => handleFieldChange({ field: FieldEnum.ApartmentNumber, event })}
              onFocus={clearAutocompleteField}
              errorMessage={apartmentNumberErrorMessage}
              isInvalid={Boolean(apartmentNumberErrorMessage)}
            />
          </Wrapper>
        </>
      )}
      <HintBox>
        <Stack as="ul" gap="1x">
          <li>{t('address.info_box.complete_address')}</li>
          <li>{t('address.info_box.locked_after_publish')}</li>
        </Stack>
      </HintBox>
    </SectionWrapper>
  )
}
