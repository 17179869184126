import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Paragraph, Spacer, useBreakpointValue, DisplayText } from '@qasa/qds-ui'
import { reportError } from '@qasa/app'
import { useTheme } from '@emotion/react'

import { currentBrand } from '../brands'
import { PageChrome } from '../ui-shared/_layout/page-chrome'
import { NotFoundIllustration } from '../assets/illustrations/qasa/not-found'
import { LinkButton } from '../ui-shared/_core/link-button'

const ContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.spacing['16x'],
}))
export function NotFound() {
  const { t } = useTranslation('page_not_found')
  const { state, pathname } = useLocation()
  const theme = useTheme()
  const illustrationHeight = useBreakpointValue({
    base: theme.sizes['20x'],
    sm: theme.sizes['24x'],
    md: theme.sizes['128'],
  })

  useEffect(() => {
    reportError('404: page not found', { pathname, state })
  }, [pathname, state])

  return (
    <PageChrome>
      <ContentContainer>
        {currentBrand !== 'blocket' && <NotFoundIllustration height={illustrationHeight} />}
        <Spacer size="8x" />
        <DisplayText size={{ base: 'xl', sm: '2xl', md: '3xl' }} as="h1">
          {'404'}
        </DisplayText>
        <Spacer size="5x" />
        <Paragraph
          size={{
            base: 'md',
            sm: 'lg',
            md: 'xl',
          }}
        >
          {t([`message_${currentBrand}`, 'message'])}
        </Paragraph>
        <Spacer size="12x" />
        <LinkButton to="/">{t('go_to_home')}</LinkButton>
      </ContentContainer>
    </PageChrome>
  )
}
