import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Button, Stack, Spacer, Link } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import type { ZendeskArticleSection, ZendeskArticle } from '../../helpers/zendesk-api'
import { ZendeskAPI } from '../../helpers/zendesk-api'
import { useAuthContext } from '../../context/auth-context'
import { Loading } from '../../ui-shared/_core/network-statuses'
import { BRAND_TOGGLES } from '../../brands/toggles'
import { EXTERNAL_LINKS } from '../../brands/external-links'
import { useCurrentLocale } from '../../translations/use-current-locale'

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing['4x'],
  borderRadius: theme.radii.xl,
  backgroundColor: theme.colors.core.gray10,
}))

const Content = styled.div(({ theme }) => ({
  padding: theme.spacing['2x'],
}))

const LinkRow = styled(Link)({
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
})

const HelpCenterLink = Button

type Props = { section?: ZendeskArticleSection; className?: string }

export function FaqPanel({ section, className }: Props) {
  const { t } = useTranslation('faq_panel')
  const [articles, setArticles] = useState<ZendeskArticle[]>([])
  const [isLoading, setIsLoading] = useState(Boolean(section && BRAND_TOGGLES.faqPanelEnabled))
  const { authBody } = useAuthContext()
  const lang = useCurrentLocale()

  useEffect(() => {
    let isMounted = true
    async function fetchArticles() {
      const role = authBody ? (authBody.landlord ? 'landlord' : 'tenant') : ''
      const articles = await ZendeskAPI.getArticles({ section: section!, role, language: lang })
      if (!isMounted) {
        return
      }
      setArticles(articles)
      setIsLoading(false)
    }

    if (section && BRAND_TOGGLES.faqPanelEnabled && process.env.NODE_ENV !== 'test') {
      setIsLoading(true)
      fetchArticles()
    }

    return () => {
      isMounted = false
    }
  }, [authBody, lang, section])

  if (isLoading) {
    return (
      <Wrapper>
        <Loading testId="faq-loader" />
      </Wrapper>
    )
  }

  if (!articles.length) {
    return null
  }

  return (
    <Wrapper className={className}>
      <Content>
        <Heading size="2xs">{t('title')}</Heading>
        <Spacer size="8x" />
        <Stack gap="4x">
          {articles.map(({ title, url }) => (
            <LinkRow href={url} target="_blank" key={title}>
              {title}
            </LinkRow>
          ))}
        </Stack>
      </Content>
      <Spacer size="12x" />
      <Stack alignItems="flex-end">
        <HelpCenterLink
          as="a"
          href={EXTERNAL_LINKS.helpCenterUrl}
          variant="tertiary"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link')}
        </HelpCenterLink>
      </Stack>
    </Wrapper>
  )
}
