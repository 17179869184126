import axios from 'axios'
import type { MeQueryQuery } from '@qasa/graphql'
import { AccountResourceStateEnum } from '@qasa/graphql'

import { currentBrand } from '../brands'

import { getP1AccessToken, getAdminToken, encodeBasicAuthenticationToken } from './p1-auth'
import { ENV } from './env'

const ACCESS_TOKEN_HEADER_NAME = 'Access-Token'

const apiClient = axios.create({
  baseURL: ENV.SERVER_URL,
  headers: {
    [ACCESS_TOKEN_HEADER_NAME]: getAdminToken() || getP1AccessToken(),
  },
})

let unauthenticatedInterceptor: number
export function removeUnauthenticatedInterceptor() {
  apiClient.interceptors.response.eject(unauthenticatedInterceptor)
}
export function addUnauthenticatedInterceptor({ onUnauthenticated }: { onUnauthenticated: () => void }) {
  unauthenticatedInterceptor = apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      // call onUnauthenticated function (for example to log out) if we pick up a 401 response
      if (error.response?.status === 401) {
        onUnauthenticated()
      }
      return Promise.reject(error)
    },
  )
}

function updateHeaders(headers: Record<string, string>) {
  apiClient.defaults.headers = { ...apiClient.defaults.headers, ...headers }
}

type OldMeQuery = Omit<NonNullable<MeQueryQuery['me']>, '__typename' | 'certificate'> &
  NonNullable<MeQueryQuery['me']>['private'] & {
    bio: NonNullable<MeQueryQuery['me']>['bio']
    picture: NonNullable<MeQueryQuery['me']>['profilePicture']
  }

function convertData({
  familyName,
  phoneNumber,
  email,
  birthDate,
  bio,
  uid,
  orgNumber,
  organisationNumberCountryCode,
  contactLocation,
  activeBankAccount,
  picture,
  amplitudeId,
  isOnfidoReverificationAvailable,
  ...rest
}: OldMeQuery): NonNullable<MeQueryQuery['me']> {
  return {
    ...rest,
    __typename: 'User',
    certificate: null,
    uid,
    private: {
      stripeAccount: {
        __typename: 'AccountResource',
        id: '1',
        currentState: AccountResourceStateEnum.actions_required,
      },
      smoochParams: {
        __typename: 'SmoochParams',
        smoochJwt: null,
      },
      familyName,
      phoneNumber,
      isOnfidoReverificationAvailable,
      email,
      birthDate,
      orgNumber,
      organisationNumberCountryCode,
      amplitudeId,
      homeqUid: null, // TODO: Add to rest
      verifiedAccess: false,
      invoiceAdsFrom: null,
      activeBankAccount,
      __typename: 'UserPrivate',
      id: uid,
      bankAccounts: [],
      contactLocation,
      schibstedAccountId: null,
    },
    bio,
    profilePicture: picture,
  }
}

const getParsedUserResponse = ({ data, status }: { data: LegitimateAny; status: number }) => {
  const convertedData = convertData(data)
  return { data: convertedData, accessToken: data.accessToken, status }
}

type UserCredentials = { email: string; password: string }

function adminLogin(payload: { user: UserCredentials }) {
  const { email, password } = payload.user
  return apiClient
    .get('/v1/users/token/agnostic', {
      headers: {
        Authorization: `Basic ${encodeBasicAuthenticationToken(email, password)}`,
      },
    })
    .then(getParsedUserResponse)
}

function login({ user }: { user: UserCredentials }) {
  const { email, password } = user
  return apiClient
    .post('/v1/users/token', {
      user: {
        email,
        password,
        main_platform: currentBrand,
      },
    })
    .then(getParsedUserResponse)
}

type CreateUserParams = UserCredentials & { tenant: boolean; landlord: boolean }
function createUser({ user }: { user: CreateUserParams }) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, password, tenant, landlord } = user
  return apiClient
    .post('/v1/users', {
      user: {
        email,
        password,
        tenant,
        landlord,
        main_platform: currentBrand,
      },
    })
    .then(getParsedUserResponse)
}

/* Sends an email with a link to change password */
function resetPasswordRequest({ user }: { user: Pick<UserCredentials, 'email'> }) {
  const { email } = user
  return apiClient.post('/v1/users/password', {
    user: {
      email,
      platform: currentBrand,
    },
  })
}

/* Changes the user password */
type ResetPasswordParams = {
  user: Pick<UserCredentials, 'password'> & { resetPasswordToken: string }
}
function resetPassword({ user }: ResetPasswordParams) {
  const { password, resetPasswordToken } = user
  return apiClient.put('/v1/users/password', {
    user: {
      password,
      resetPasswordToken,
      platform: currentBrand,
    },
  })
}

/**
 * Endpoint that allows admins to login as user
 */
type GetUserToken = { uid: string; adminToken: string }
function getUserToken({ uid, adminToken }: GetUserToken) {
  return apiClient.get('/v1/admin/users/token', {
    params: { uid },
    headers: { 'Access-Token': adminToken },
  })
}

const initializeGuaranteeCheck = function () {
  return apiClient.post('/v1/certificates')
}

/* This uses fetch since axios doesn't support streaming responses yet */
const getSuggestedDescription = ({ homeId }: { homeId: string }) => {
  return fetch(`${ENV.SERVER_URL}/v1/home_evaluation/suggested_description?homeId=${homeId}`, {
    headers: {
      accept: 'application/json',
      [ACCESS_TOKEN_HEADER_NAME]: getAdminToken() || getP1AccessToken(),
    } as HeadersInit,
  })
}

export const P1 = {
  login,
  createUser,
  resetPasswordRequest,
  resetPassword,
  adminLogin,
  getUserToken,
  updateHeaders,
  initializeGuaranteeCheck,
  ACCESS_TOKEN_HEADER_NAME,
  getSuggestedDescription,
}
