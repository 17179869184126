import { useHistory } from 'react-router-dom'
import { parse } from 'query-string'
import styled from '@emotion/styled'

import { Wizard } from '../ui-shared/wizard'
import { CreditVerificationStep } from '../ui-page-modules/application-flow/steps/credit-verification-step'

const Wrapper = styled.div({
  margin: 'auto',
})

export function CreditVerification() {
  const history = useHistory()
  const { previous_path } = parse(history.location.search) as Record<string, string | undefined>
  const redirectUrl = previous_path ? previous_path : '/settings'

  return (
    <Wizard closeLink={redirectUrl}>
      <Wrapper>
        <CreditVerificationStep
          onNext={() => {
            history.push(redirectUrl)
          }}
          onBack={() => {
            history.push(redirectUrl)
          }}
          isSettingsVerificationFlow
        />
      </Wrapper>
    </Wizard>
  )
}
