import { useState, useEffect } from 'react'

import { HomeFilterRangeSlider } from '../../components/home-filter-range-slider'
import { useLocaleConfig } from '../../../../../configs/use-locale-config'
import { useAppTranslation } from '../../../../../contexts/i18next'
import { useDebouncedValue } from '../../../../../hooks'
import { formatNumber } from '../../../../../utils/number'
import { useFindHomeFiltersContext } from '../../../contexts'

export function RentFilterSection() {
  const { filterValues, updateFilterValues } = useFindHomeFiltersContext()
  const { currency } = useLocaleConfig()
  const defaultMinMonthlyCost = 0
  const defaultMaxMonthlyCost = currency === 'EUR' ? 3_000 : 30_000
  const stepSize = currency === 'EUR' ? 10 : 100
  const { t } = useAppTranslation('filter_sections')

  const [sliderValue, setSliderValue] = useState([filterValues.minMonthlyCost, filterValues.maxMonthlyCost])
  const debouncedFilterValue = useDebouncedValue({ value: sliderValue, delay: 300 })

  useEffect(() => {
    const [minCost, maxCost] = debouncedFilterValue
    const sanitizedMin = minCost === defaultMinMonthlyCost ? null : minCost
    const sanitizedMax = maxCost === defaultMaxMonthlyCost ? null : maxCost

    updateFilterValues({ minMonthlyCost: sanitizedMin, maxMonthlyCost: sanitizedMax })
  }, [defaultMaxMonthlyCost, debouncedFilterValue, updateFilterValues])

  // If value changes by clicking the clear button we need to manually update the slider value
  useEffect(() => {
    setSliderValue([filterValues.minMonthlyCost, filterValues.maxMonthlyCost])
  }, [filterValues.minMonthlyCost, filterValues.maxMonthlyCost])

  const handleFormatValue = (value: number) => {
    const formattedValue = formatNumber({ amount: value, currency })
    if (value === defaultMaxMonthlyCost) {
      return formattedValue + '+'
    }
    return formattedValue
  }
  const value = [sliderValue[0] || defaultMinMonthlyCost, sliderValue[1] || defaultMaxMonthlyCost] as [
    number,
    number,
  ]

  return (
    <HomeFilterRangeSlider
      value={value}
      label={t('rent.monthly_cost')}
      onChange={setSliderValue}
      step={stepSize}
      min={defaultMinMonthlyCost}
      max={defaultMaxMonthlyCost}
      formatValue={handleFormatValue}
      minRange={10}
    />
  )
}
