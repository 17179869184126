/**  Default is 'crop', which fits the image to your exact proportions.
 * To keep the original aspect ratio use 'fit-in' instead.
 * Docs: (we use an older version than this)
 *  https://thumbor.readthedocs.io/en/latest/usage.html */
import type { GetPrimaryUploadFragment, GetSortedUploadsFragment } from '@qasa/graphql'
import { graphql } from '@qasa/graphql'
import type { StaticImageData } from 'next/image'

import { CURRENT_PLATFORM, getEnv } from '../env'

export type ThumborFittingMethods = 'fit-in' | 'adaptive-fit-in' | 'full-fit-in' | 'crop'

export type ThumborConfig = {
  width?: number
  height?: number
  fittingMethod?: ThumborFittingMethods
}

const DEFAULT_THUMBOR_SIZES: Record<typeof CURRENT_PLATFORM, ThumborConfig> = {
  native: {
    width: 350,
    height: 600,
  },
  web: {
    width: 1200,
    height: 1200,
  },
}

type PrependSrcWithThumbor = {
  src: string | StaticImageData
} & ThumborConfig

export const prependSrcWithThumbor = ({
  src: srcProp,
  fittingMethod = 'crop',
  width = DEFAULT_THUMBOR_SIZES[CURRENT_PLATFORM].width,
  height = DEFAULT_THUMBOR_SIZES[CURRENT_PLATFORM].height,
}: PrependSrcWithThumbor) => {
  // If it's a static image import, we need to extract the src from the object.
  const src = typeof srcProp === 'string' ? srcProp : srcProp.src

  if (src.includes('qasa-static') && !src.includes('localhost')) {
    const urlPrefix = getEnv('IMAGES_CDN_URL')
    const resolution = `${width}x${height}`

    if (fittingMethod === 'crop') {
      return `${urlPrefix}${resolution}/smart/${src}`
    }
    return `${urlPrefix}${fittingMethod}/${resolution}/${src}`
  } else {
    return src
  }
}

graphql(`
  fragment GetSortedUploads on Home {
    uploads {
      id
      url
      metadata {
        order
      }
    }
  }
`)
export const getSortedUploads = ({ uploads }: Omit<GetSortedUploadsFragment, '__typename'>) => {
  const sortedByOrder = uploads.slice().sort((a, b) => {
    const aOrder = a.metadata?.order
    const bOrder = b.metadata?.order
    if (aOrder && bOrder && aOrder !== bOrder) {
      return aOrder - bOrder
    }
    return a.id > b.id ? 1 : -1
  })
  return sortedByOrder
}

graphql(`
  fragment GetPrimaryUpload on Home {
    ...GetSortedUploads
    uploads {
      metadata {
        primary
      }
    }
  }
`)
export const getPrimaryUpload = ({ uploads = [] }: Omit<GetPrimaryUploadFragment, '__typename'>) => {
  const primaryUploads = uploads.filter((x) => x.metadata?.primary)

  if (primaryUploads.length) {
    return primaryUploads[primaryUploads.length - 1]
  } else {
    return getSortedUploads({ uploads })[0]
  }
}
